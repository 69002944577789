import { Component, OnInit, Input } from "@angular/core";
import * as Highcharts from "highcharts";
import HC_exporting from "highcharts/modules/exporting";

@Component({
  selector: "app-widget-area",
  templateUrl: "./area.component.html",
  styleUrls: ["./area.component.scss"]
})
export class AreaComponent implements OnInit {  
  @Input() areaData = [];
  Highcharts = Highcharts;
  chartOptions = {};

  constructor() {}

  ngOnInit(): void {
    this.chartOptions = {
      chart: {
        type: "area"
      },
      title: {
        text: "Historic and Estimated Worldwide Population Growth by Region"
      },
      subtitle: {
        text: "Source: Wikipedia.org"
      },
      xAxis: {
        categories: ["1750", "1800", "1850", "1900", "1950", "1999", "2050"],
        tickmarkPlacement: "on",
        title: {
          enabled: false
        }
      },
      yAxis: {
        title: {
          text: "Billions"
        },
        labels: {
          formatter: function() {
            return this.value / 1000;
          }
        }
      },
      credits: {
        enabled: false
      },
      exporting: {
        enabled: true
      },
      tooltip: {
        split: true,
        valueSuffix: " millions"
      },
      series: this.areaData
    };

    HC_exporting(Highcharts);

    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 300);
  }
}
