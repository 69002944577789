import { DashboardService } from "./../dashboard.service";
import { Component, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"]
})
export class DashboardComponent implements OnInit {
  areaData = [];
  cardData = [];
  pieData = [];
  tableData = [];

  constructor(private dashboardService: DashboardService) {}

  ngOnInit(): void {
    this.areaData = this.dashboardService.areaData();
    this.cardData = this.dashboardService.cardData();
    this.pieData = this.dashboardService.pieData();
    this.tableData = this.dashboardService.tableData();
  }
}
