<div>
  <app-widget-area [areaData]="areaData"></app-widget-area>
</div>

<br />

<mat-divider></mat-divider>

<br />

<div
  fxLayout="row"
  fxLayoutAlign="space-between center"
  fxLayoutGap="20px"
  class="stats-cards"
>
  <mat-card fxFlex="25">
    <app-widget-card
      label="New Users"
      total="12k"
      percentage="50"
      [cardData]="cardData"
    ></app-widget-card>
  </mat-card>
  <mat-card fxFlex="25">
    <app-widget-card
      label="Users retention"
      total="10k"
      percentage="40"
      [cardData]="cardData"
    ></app-widget-card>
  </mat-card>
  <mat-card fxFlex="25">
    <app-widget-card
      label="Users engagement"
      total="8k"
      percentage="30"
      [cardData]="cardData"
    ></app-widget-card>
  </mat-card>
  <mat-card fxFlex="25">
    <app-widget-card
      label="Referral"
      total="6k"
      percentage="20"
      [cardData]="cardData"
    ></app-widget-card>
  </mat-card>
</div>

<br />

<mat-divider></mat-divider>

<br />

<div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
  <div fxFlex="60">
    <app-widget-table [tableData]="tableData"></app-widget-table>
  </div>
  <div fxFlex="40">
    <app-widget-pie [pieData]="pieData"></app-widget-pie>
  </div>
</div>
