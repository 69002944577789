import { CovidComponent } from "./../../modules/covid/covid.component";
import { DashboardService } from "./../../modules/dashboard.service";
import { MatDividerModule } from "@angular/material/divider";
import { SharedModule } from "./../../shared/shared.module";
import { PostsComponent } from "./../../modules/posts/posts.component";
import { RouterModule } from "@angular/router";
import { DashboardComponent } from "./../../modules/dashboard/dashboard.component";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DefaultComponent } from "./default.component";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatCardModule } from "@angular/material/card";
import { FlexLayoutModule } from "@angular/flex-layout";

@NgModule({
  declarations: [
    DefaultComponent,
    DashboardComponent,
    PostsComponent,
    CovidComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    MatSidenavModule,
    MatDividerModule,
    FlexLayoutModule,
    MatCardModule
  ],
  providers: [DashboardService]
})
export class DefaultModule {}
