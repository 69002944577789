<mat-nav-list>
  <div class="profile-card">
    <div class="header">
      <h2>SON DUONG</h2>
      <h4>A hooby Project about Angular and Highcharts</h4>
      <h4>son@tduong.de</h4>
    </div>
    <img
      src="https://angular.io/assets/images/logos/angular/angular.png"
      alt="Son DUONG"
    />
  </div>
  <mat-divider></mat-divider>

  <h2 matSubheader>Pages</h2>

  <a mat-list-item routerLinkActive="list-item-active" routerLink="/"
    >Dashboard</a
  >
  <a mat-list-item routerLinkActive="list-item-active" routerLink="/posts"
    >Posts</a
  >
  <a mat-list-item routerLinkActive="list-item-active" routerLink="/covid"
    >Tutorials</a
  >
  <a mat-list-item routerLinkActive="list-item-active" routerLink="/articles"
    >Articles</a
  >

  <mat-divider></mat-divider>

  <h2 matSubheader>Tools</h2>
  <a mat-list-item routerLinkActive="list-item-active" routerLink="/contacts">
    <mat-icon>import_contacts</mat-icon>
    Contacts
  </a>
  <a mat-list-item routerLinkActive="list-item-active" routerLink="/contacts">
    <mat-icon>contact_phone</mat-icon>
    Leads
  </a>
</mat-nav-list>
